import React from "react"
import styled from "styled-components"

import Icon from "./icons"
import { socialMedia } from "config"

const StyledSocialContainer = styled.div`
  width: 100vw;
  height: fit-content;
  position: absolute;
  bottom: 2rem;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    width: fit-content;
    height: 100vh;
    right: 2.5rem;
    left: unset;
    top: 0;
  }
`
const StyledSocialWrapper = styled.div`
  padding: 0 0.3rem;
  display: flex;
  width: fit-content;
  background: ${({ theme }) => theme.colors.tertiary};
  border: 1px solid ${({ theme }) => theme.colors.border};
  border-radius: 8px;
  height: fit-content;
  @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    flex-direction: column;
    padding: 0.3rem 0;
    background: white;
    border: none;
  }
`

const StyledSocialProfile = styled.a`
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    height: auto;
    width: 14px;
  }
  &:hover svg {
    filter: invert(1);
    transform: scale(1.1);
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    width: 49px;
    height: 49px;
    svg {
      height: 18px;
      width: auto;
    }

  }
`

const Social = () => {
  return (
    <StyledSocialContainer>
      <StyledSocialWrapper itemCount={socialMedia.length}>
        {socialMedia.map(({ name, url }, key) => {
          return (
            <StyledSocialProfile
              key={key}
              href={url}
              target="_blank"
              rel="nofollow noopener noreferrer"
              aria-label={name}
            >
              <Icon
                name={name}
                color="#718096"
              />
            </StyledSocialProfile>
          )
        })}
      </StyledSocialWrapper>
    </StyledSocialContainer>
  )
}

export default Social
