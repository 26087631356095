import React from "react"
import PropTypes from "prop-types"

const Twitter = ({ color }) => {
  return (
    <svg
      version="1.1"
      role="img"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width="21"
      height="17"
      viewBox="0 0 21 17"
    >
    <path fillRule="evenodd" clipRule="evenodd" d="M20.3349 0.38575C19.5029 0.778771 18.087 1.51581 17.3755 1.65669C17.3479 1.66383 17.3255 1.67302 17.2989 1.68017C16.469 0.861459 15.3318 0.354105 14.0731 0.354105C11.5363 0.354105 9.47933 2.41108 9.47933 4.94785C9.47933 5.08158 9.46811 5.32761 9.47933 5.45827C6.19429 5.45827 3.79533 3.74429 1.99969 1.54133C1.75979 1.24529 1.50459 1.39944 1.4515 1.60973C1.33206 2.08544 1.29123 2.88067 1.29123 3.44825C1.29123 4.87844 2.40904 6.2831 4.14956 7.15388C3.82902 7.23656 3.47581 7.29577 3.10831 7.29577C2.67548 7.29577 2.17731 7.18246 1.74142 6.95379C1.58013 6.86906 1.23202 6.89254 1.33513 7.30496C1.74856 8.95769 3.63506 10.1184 5.32046 10.4563C4.93765 10.6819 4.12098 10.6359 3.74531 10.6359C3.60648 10.6359 3.12363 10.6033 2.81125 10.5645C2.52644 10.5298 2.0885 10.6033 2.45498 11.1586C3.24204 12.3499 5.02238 13.0982 6.55465 13.1268C5.14386 14.2334 2.83677 14.6376 0.626669 14.6376C0.179544 14.6274 0.202002 15.1368 0.562356 15.3256C2.19263 16.1811 4.86313 16.6874 6.77106 16.6874C14.3559 16.6874 18.6668 10.9065 18.6668 5.45725C18.6668 5.36946 18.6648 5.18571 18.6617 5.00094C18.6617 4.98256 18.6668 4.96521 18.6668 4.94683C18.6668 4.91927 18.6587 4.89273 18.6587 4.86517C18.6556 4.72633 18.6525 4.59669 18.6495 4.52931C19.2507 4.09546 20.1715 3.34208 20.637 2.76531C20.7953 2.56931 20.6677 2.33146 20.4523 2.40598C19.898 2.59892 18.9394 2.97254 18.3391 3.044C19.5407 2.24877 20.1348 1.55665 20.6452 0.787959C20.8198 0.525605 20.6013 0.259167 20.3349 0.38575Z" fill={color}/>
    </svg>
  )
}

Twitter.propTypes = {
  color: PropTypes.string,
}

Twitter.defaultProps = {
  color: "#000000",
}

export default Twitter