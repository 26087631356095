import React from "react"
import PropTypes from "prop-types"

const ImageSVG = ({ color }) => {
  return (
    <svg
      version="1.1"
      role="img"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width="38"
      height="42"
      viewBox="0 0 38 42"
    >
      <path d="M35.4812 9.27394L26.5443 1.47994C26.3716 1.32855 26.1636 1.26086 25.9205 1.27688L8.89799 2.31693C8.2092 2.36231 7.57961 2.68864 7.12544 3.20687C6.67126 3.72511 6.43035 4.39207 6.47573 5.08086L6.70261 8.52482L3.29917 8.74904C2.61038 8.79442 1.98079 9.12075 1.52394 9.59847C1.06977 10.1167 0.828855 10.7837 0.874232 11.4725L2.67598 38.8215C2.76941 40.2396 4.02181 41.3372 5.43991 41.2438L29.1019 39.685C30.5201 39.5915 31.6176 38.3391 31.5242 36.921L31.2973 33.4771L34.7413 33.2502C36.1594 33.1568 37.2597 31.9449 37.1636 30.4863L35.8049 9.86301C35.751 9.6631 35.6135 9.428 35.4812 9.27394ZM29.7793 36.9953C29.8113 37.4815 29.4302 37.8728 28.9845 37.9022L5.32247 39.4611C4.83626 39.4931 4.44491 39.1119 4.41555 38.6663L2.61647 11.3577C2.60046 11.1146 2.66814 10.9066 2.81954 10.7339C2.97093 10.5612 3.16817 10.4668 3.41128 10.4508L6.85524 10.2239L8.31532 32.3868C8.40875 33.8049 9.62064 34.9051 11.0793 34.809L29.5956 33.5892L29.8198 36.9926L29.7793 36.9953ZM35.3808 30.6037C35.4128 31.0899 35.0722 31.4786 34.586 31.5106L10.924 33.0695C10.4378 33.1015 10.0491 32.7609 10.017 32.2747L8.21796 4.96608C8.20195 4.72298 8.26964 4.51506 8.42103 4.34231C8.57242 4.16956 8.76967 4.07518 9.01277 4.05917L25.1818 3.03465L25.6783 10.5708C25.7103 11.0571 26.099 11.3977 26.5852 11.3657L34.1214 10.8692L35.4213 30.601L35.3808 30.6037Z" fill={color} fillOpacity="0.25"/>
      <path d="M25.707 22.1256L24.7581 23.1648C24.4175 23.5535 23.8071 23.553 23.4536 23.1286L19.9725 19.0852C19.5758 18.623 18.8491 18.7116 18.557 19.2191L13.7421 28.2854C13.4149 28.8766 13.8681 29.5792 14.5569 29.5339L30.4396 28.4875C31.1284 28.4421 31.4828 27.6456 31.0403 27.1051L27.0142 22.2023C26.7013 21.7753 26.0908 21.7748 25.707 22.1256Z" fill={color} fillOpacity="0.25"/>
      <path d="M27.646 19.4345C28.8767 19.3534 29.8087 18.29 29.7276 17.0592C29.6465 15.8285 28.5831 14.8965 27.3523 14.9776C26.1216 15.0587 25.1896 16.1221 25.2707 17.3528C25.3518 18.5836 26.4152 19.5156 27.646 19.4345Z" fill={color} fillOpacity="0.25"/>
    </svg>
  )
}

ImageSVG.propTypes = {
  color: PropTypes.string,
}

ImageSVG.defaultProps = {
  color: "#000000",
}

export default ImageSVG