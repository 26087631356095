import React, { useRef, useContext, useEffect } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { motion, useAnimation } from "framer-motion"

import Context from "context/"
import ContentWrapper from "styles/contentWrapper"
import Dropzone from "components/dropzone"
import Social from "components/social"

const StyledSection = styled.section`
  width: 100%;
  height: auto;
  z-index: 10;
  padding-left: 0;
  @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    padding-left: 2rem;
  }
`

const StyledContentWrapper = styled(ContentWrapper)`
  && {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding-bottom: 5rem;
    .section-title {
      color: white;
      margin-bottom: 0.41174rem;
      text-align: center;
    }
    .section-subtitle {
      color: #C2CAD9;
      margin-bottom: 1.7646rem;
      text-align: center;
    }
    @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
      max-width: 33.41rem;
      .section-title {
        color: white;
        margin-bottom: 0.82348rem;
        text-align: left;
      }
      .section-subtitle {
        color: #C2CAD9;
        margin-bottom: 2.82336rem;
        text-align: left;
      }
      padding-bottom: 2rem;
    }
  }
`


const Drop = ({ content, addProfile, profile }) => {
  const { frontmatter, body } = content[0].node

  const { isIntroDone } = useContext(Context).state
  const tControls = useAnimation()

  // Only trigger animations if the intro is done or disabled
  useEffect(() => {
    if (isIntroDone) {
      tControls.start({ opacity: 1, y: 0 })
    }
  }, [isIntroDone, tControls])
  return (
    <StyledSection id="drop">
      <StyledContentWrapper>
        <motion.div
          className="inner-wrapper"
          initial={{ opacity: 0, y: 20 }}
          animate={tControls}
        >
          <Dropzone
            addProfile={addProfile}
            profile={profile}
            frontmatter={frontmatter}
          />
        </motion.div>
      </StyledContentWrapper>
      <Social />
    </StyledSection>
  )
}

Drop.propTypes = {
  content: PropTypes.arrayOf(
    PropTypes.shape({
      node: PropTypes.shape({
        frontmatter: PropTypes.object.isRequired,
      }).isRequired,
    }).isRequired
  ).isRequired,
}

export default Drop
