import React from "react"
import PropTypes from "prop-types"

const Facebook = ({ color }) => {
  return (
    <svg
      version="1.1"
      role="img"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width="19"
      height="20"
      viewBox="0 0 19 20"
    >
      <path fillRule="evenodd" clipRule="evenodd" d="M14.6042 0.8125H4.39583C2.14081 0.8125 0.3125 2.64081 0.3125 4.89583V15.1042C0.3125 17.3592 2.14081 19.1875 4.39583 19.1875H10.1339V12.0815H7.74213V9.29971H10.1339V7.25294C10.1339 4.88052 11.5845 3.58713 13.7018 3.58713C14.4153 3.58508 15.1279 3.62183 15.8373 3.69431V6.17494H14.3796C13.226 6.17494 13.0015 6.72006 13.0015 7.52448V9.29563H15.7577L15.3994 12.0774H12.9851V19.1875H14.6042C16.8592 19.1875 18.6875 17.3592 18.6875 15.1042V4.89583C18.6875 2.64081 16.8592 0.8125 14.6042 0.8125Z" fill="#718096"/>
    </svg>
  )
}

Facebook.propTypes = {
  color: PropTypes.string,
}

Facebook.defaultProps = {
  color: "#000000",
}

export default Facebook