import React, {useEffect} from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import { connect } from "react-redux"
import { addProfile } from "store/actions/profile"
import GlobalStateProvider from "context/provider"
import Layout from "components/layout"
import SEO from "components/seo"
import Drop from "components/sections/drop"
import { siteTitle } from "config"
import { isSSR } from "utils/"

const IndexPage = ({ data, addProfile, profile }) => {
  const { frontmatter } = data.index.edges[0].node
  const { useSplashScreen } = frontmatter
  useEffect(() => {
    if (!isSSR) {
      const version =  window.localStorage.getItem('version');
      if(version !== "2.0.1") {
        window.localStorage.clear()
        window.localStorage.setItem('version', "2.0.1")
      }
      setTimeout(() => {
        window.analytics && window.analytics.page();
       }, 100)
    }
  }, [])
  const globalState = {
    // if useSplashScreen=false, we skip the intro by setting isIntroDone=true
    isIntroDone: useSplashScreen ? false : true,
    // darkMode is initially disabled, a hook inside the Layout component
    // will check the user's preferences and switch to dark mode if needed
    darkMode: true,
  }
  return (
    <GlobalStateProvider initialState={globalState}>
      <Layout home>
        <SEO
          title={`${siteTitle} | Accueil`}
        />
        <Drop
          content={data.drop.edges}
          addProfile={addProfile}
          profile={profile}
        />
      </Layout>
    </GlobalStateProvider>
  )
}

IndexPage.propTypes = {
  data: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({
  profile: state.profile.profile,
})

export default connect(mapStateToProps, { addProfile })(IndexPage)

export const pageQuery = graphql`
  {
    index: allMdx(filter: { fileAbsolutePath: { regex: "/index/index/" } }) {
      edges {
        node {
          frontmatter {
            seoTitle
            useSeoTitleSuffix
            useSplashScreen
          }
        }
      }
    }
    drop: allMdx(filter: { fileAbsolutePath: { regex: "/index/drop/" } }) {
      edges {
        node {
          frontmatter {
            title
            titlePrefix
            subtitle
            subtitlePrefix
          }
        }
      }
    }
  }
`
