import React from "react"
import PropTypes from "prop-types"

const Youtube = ({ color }) => {
  return (
    <svg
      version="1.1"
      role="img"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width="21"
      height="17"
      viewBox="0 0 21 17"
    >
      <path d="M20.2817 2.56485C20.0469 1.68694 19.3548 0.994813 18.4769 0.760021C16.8844 0.333313 10.5001 0.333313 10.5001 0.333313C10.5001 0.333313 4.11579 0.333313 2.52329 0.760021C1.64537 0.994813 0.953248 1.68694 0.718456 2.56485C0.291748 4.15735 0.291748 8.49998 0.291748 8.49998C0.291748 8.49998 0.291748 12.8426 0.718456 14.4351C0.953248 15.313 1.64537 16.0051 2.52329 16.2399C4.11579 16.6666 10.5001 16.6666 10.5001 16.6666C10.5001 16.6666 16.8844 16.6666 18.4769 16.2399C19.3558 16.0051 20.0469 15.313 20.2817 14.4351C20.7084 12.8426 20.7084 8.49998 20.7084 8.49998C20.7084 8.49998 20.7084 4.15735 20.2817 2.56485ZM8.45841 11.1521V5.84785C8.45841 5.45483 8.8841 5.20983 9.22404 5.40583L13.8178 8.05796C14.1577 8.25396 14.1577 8.746 13.8178 8.942L9.22404 11.5941C8.8841 11.7911 8.45841 11.5451 8.45841 11.1521Z" fill={color}/>
    </svg>
  )
}

Youtube.propTypes = {
  color: PropTypes.string,
}

Youtube.defaultProps = {
  color: "#000000",
}

export default Youtube
