import React from "react"
import PropTypes from "prop-types"

const Linkedin = ({ color }) => {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      fill="none"
      x="0px"
      y="0px"
      width="19px"
      height="19px"
      viewBox="0 0 19 19"
    >
      <g>
        <path fillRule="evenodd" clipRule="evenodd" d="M16.6458 0.3125H2.35417C1.22615 0.3125 0.3125 1.22615 0.3125 2.35417V16.6458C0.3125 17.7739 1.22615 18.6875 2.35417 18.6875H16.6458C17.7739 18.6875 18.6875 17.7739 18.6875 16.6458V2.35417C18.6875 1.22615 17.7739 0.3125 16.6458 0.3125ZM6.4375 14.6042H3.86194V7.45833H6.4375V14.6042ZM5.10429 6.1486C4.31723 6.1486 3.7915 5.6239 3.7915 4.9236C3.7915 4.22331 4.31621 3.6986 5.19106 3.6986C5.97813 3.6986 6.50385 4.22331 6.50385 4.9236C6.50385 5.6239 5.97915 6.1486 5.10429 6.1486ZM15.625 14.6042H13.1321V10.6985C13.1321 9.61842 12.4676 9.36933 12.2185 9.36933C11.9694 9.36933 11.1384 9.53573 11.1384 10.6985C11.1384 10.8649 11.1384 14.6042 11.1384 14.6042H8.56287V7.45833H11.1384V8.45569C11.4702 7.87381 12.1348 7.45833 13.3812 7.45833C14.6276 7.45833 15.625 8.45569 15.625 10.6985V14.6042Z" fill={color}/>
      </g>
    </svg>
  )
}

Linkedin.propTypes = {
  color: PropTypes.string,
}

Linkedin.defaultProps = {
  color: "#000000",
}

export default Linkedin
